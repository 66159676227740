import axiosClient from '@/lib/rest/axiosClient';
import gptModule from '@/store/modules/gptModule';
import workflowModule from '@/store/modules/workflowModule';
import { getConfigEnv } from '@/utils/helpers';
import isOnline from 'is-online';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class GptMixin extends Vue {
  get operatorList() {
    return [
      // { operatorID: 10, operatorName: 'petronas', appName: 'petronascanada' },
      { operatorID: 10, operatorName: 'petronas', appName: 'petronas' },
      { operatorID: 5, operatorName: 'taprock', appName: 'taprock' },
      { operatorID: 4, operatorName: 'pdce', appName: 'pdc' },
      { operatorID: 2, operatorName: 'SWN', appName: 'swn' },
      { operatorID: 1, operatorName: 'Extraction', appName: 'civitas' },
      { operatorID: 9, operatorName: 'demo', appName: 'demo' },
      { operatorID: 5, operatorName: 'caerus', appName: 'caerus' },
    ];
  }

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }


  get  operatorDetails(){
    return this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    )
  }



  activeWaitingOnStatus({wellName, padName}){


    const allWaitingOn: any = []
     const wellWaitingOn = gptModule.waitingOnStatusByWellName(wellName)

     if(wellWaitingOn){
      allWaitingOn.push(wellWaitingOn)
     }


     if(padName){
      const padWaitingOn = gptModule.waitingOnStatusByWellName(padName)
      allWaitingOn.push(padWaitingOn)
     }

     if(allWaitingOn && allWaitingOn.length){
      return allWaitingOn.reduce((latest, current) => {
        const currentDate = new Date(current.date);
        const latestDate = latest ? new Date(latest.date) : null;

        // Compare dates and update latest if the current date is greater
        if (!latestDate || currentDate > latestDate) {
            return current;
        } else {
            return latest;
        }
    }, null);
     }

     return wellWaitingOn;
  }


  getRcentWaitingOnStatus({wellWaitingOn, padWaitingOn}){


    const allWaitingOn: any = []
     if(wellWaitingOn){
      allWaitingOn.push(wellWaitingOn)
     }
     if(padWaitingOn){
      allWaitingOn.push(padWaitingOn)
     }

     if(allWaitingOn && allWaitingOn.length){
      return allWaitingOn.reduce((latest, current) => {
        const currentDate = new Date(current.date);
        const latestDate = latest ? new Date(latest.date) : null;

        // Compare dates and update latest if the current date is greater
        if (!latestDate || currentDate > latestDate) {
            return current;
        } else {
            return latest;
        }
    }, null);
     }

     return wellWaitingOn;
  }




  async getAlarms() {
    const alarms = workflowModule.wellsAlarms
    if(alarms && alarms.length){
      return;
    }
    try {
      const operatorDetails: any = this.operatorList.find(
        (o) => o['appName'] === this.operatorName
      );

      let nodeID = '#WELL_ALARM_LEVEL'
      let level = 'Well'
      const operatorName  = operatorDetails['operatorName']

      if(operatorName === 'pdce'){
         nodeID = '#LEVEL_ALARM'
         level = null
      }

      const metaData = {
        operator: operatorDetails['operatorName'],
        node_id: nodeID,
        node_level:level
      };

      const response = await axiosClient.get('/alarm/', {
        params: metaData,
      });

      if(response && response.data){
        const {data :{dataset: alarmsData}} = response
        if(alarmsData){
        const wellAlarms = (alarmsData.filter(a => a.alarm || a.shutdown));
          workflowModule.setWellsAlarms(wellAlarms)
        }

      }
    } catch (error) {
      console.log(error);
    }
  }

}
